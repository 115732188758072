.CardsPageContainer {
  border-radius: 10%;
  height: 100%;
  width: 20%;
  margin: 0;
  padding: 0;
  overflow: hidden;
  cursor: pointer;
  text-decoration: none;
  color: black;
}

.CardImg {
  width: 100%;
  height: 100%;
}

.AboutCard {
  position: relative;
  margin: 0;
  padding: 0;
  transition: 0.7s ease-in-out;
  height: 100%;
  border-radius: 10%;
  background-color: #6c757d;
  opacity: 0;
  font-size: 1.5vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.CardImg:hover + .AboutCard{
  transform: translate(0%, -101%);
  -ms-transform: translate(-50%, -50%);
  opacity: 0.8;
}

 .AboutCard:hover { 
  transform: translate(0%, -101%);
  -ms-transform: translate(-50%, -50%);
  opacity: 0.8;
} 

.AboutCardHead {
  margin: 0 0 5px 0;
  padding: 10px;
  font-size: 2.25vw;
  /* background-color: greenyellow; */
}

.AboutCardLines {
  margin: 5px 0 0 0;
  padding: 10px;
  font-size: 1.25vw;
  /* background-color: hotpink; */
}

@media only screen and (max-width: 1024px) {
  .CardsPageContainer {
    height: 80%;
  }
}

@media only screen and (max-width: 600px) {
  .CardsPageContainer {
    margin: 10px;
    width: 30%;
  }
}
