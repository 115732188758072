.loaderpage {
  position: absolute;
  width: 100%;
  height: 120%;
  background-color: grey;
  opacity: 0.7;
  z-index: 50;
  display: flex;
  justify-content: center;
  align-items: center;
}
