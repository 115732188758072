.HomePage {
  background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
    url(../../Images/home-bg.jpg);
  background-position: center;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 105vh;
  min-width: 100vw;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  clip-path: polygon(50% 100%, 100% 90%, 100% 0, 0 0, 0 90%);
}

.hw {
  margin: 0;
  padding: 0;
  color: white;
  font-size: 2vw;
  letter-spacing: 1px;
}

.titles {
  margin: 25px 0 20px 0;
  padding: 0;
  color: white;
  font-size: 5vw;
  font-weight: 500;
  letter-spacing: 2.5px;
}

.SocialAccounts {
  list-style: none;
  display: flex;
  justify-content: space-between;
  padding: 2px;
  width: 15vw;
}

.SocialAccountLink {
  display: flex;
  justify-content: center;
  color: white;
  cursor: pointer;
  padding: 6px;
  transition: 1s;
  font-size: 1.2vw;
}

.SocialAccountLink:hover {
  transform: rotate(720deg);
  transition: 1s;
}

.cv {
  border: 2px solid white;
  border-radius: 30px;
  background: transparent;
  color: white;
  padding: 15px 30px;
  font-size: 1.25vw;
  transition: 0.75s;
  text-decoration: none;
}

.cv:hover {
  cursor: pointer;
  background-color: white;
  color: black;
  transition: 0.75s;
}

.footericon {
  border: 1px solid white;
  padding: 25px 2.5px 0 2.5px;
  border-radius: 30px;
  font-size: 1.6vw;
  margin: 22vh 0 -22vh;
  animation: MoveUpDown 1.5s linear infinite;
}

@-webkit-keyframes MoveUpDown {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes MoveUpDown {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px);
  }
  100% {
    transform: translateY(0);
  }
}

@media only screen and (max-width: 1024px) {
  .HomePage {
    background-attachment: scroll;
  }
  .hw {
    font-size: 4vw;
  }
  .titles {
    font-size: 8vw;
  }
  .SocialAccounts {
    width: 30vw;
  }
  .SocialAccountLink {
    font-size: 3vw;
  }
  .cv {
    margin-top: 15px;
    font-size: 2.5vw;
    padding: 20px 40px;
    border-radius: 50px;
  }
  .footericon {
    font-size: 3.2vw;
    margin: 32vh 0 -32vh;
  }
}

@media only screen and (max-width: 600px) {
  .HomePage {
    background-attachment: scroll;
  }
  .hw {
    font-size: 5vw;
  }
  .titles {
    font-size: 8vw;
  }
  .SocialAccounts {
    width: 45vw;
  }
  .SocialAccountLink {
    font-size: 3vw;
  }
  .cv {
    margin-top: 5px;
    font-size: 3.25vw;
    padding: 15px 30px;
  }
  .footericon {
    font-size: 4.8vw;
    margin: 30vh 0 -30vh;
  }
}
