body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* .App {
  text-align: center;
}*/

.HomePage_HomePage__3wCJe {
  background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
    url(/static/media/home-bg.da8d5dd6.jpg);
  background-position: center;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 105vh;
  min-width: 100vw;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  -webkit-clip-path: polygon(50% 100%, 100% 90%, 100% 0, 0 0, 0 90%);
          clip-path: polygon(50% 100%, 100% 90%, 100% 0, 0 0, 0 90%);
}

.HomePage_hw__m2p1c {
  margin: 0;
  padding: 0;
  color: white;
  font-size: 2vw;
  letter-spacing: 1px;
}

.HomePage_titles__19WuI {
  margin: 25px 0 20px 0;
  padding: 0;
  color: white;
  font-size: 5vw;
  font-weight: 500;
  letter-spacing: 2.5px;
}

.HomePage_SocialAccounts__lN5Bw {
  list-style: none;
  display: flex;
  justify-content: space-between;
  padding: 2px;
  width: 15vw;
}

.HomePage_SocialAccountLink__2-dUF {
  display: flex;
  justify-content: center;
  color: white;
  cursor: pointer;
  padding: 6px;
  transition: 1s;
  font-size: 1.2vw;
}

.HomePage_SocialAccountLink__2-dUF:hover {
  transform: rotate(720deg);
  transition: 1s;
}

.HomePage_cv__2qLEy {
  border: 2px solid white;
  border-radius: 30px;
  background: transparent;
  color: white;
  padding: 15px 30px;
  font-size: 1.25vw;
  transition: 0.75s;
  text-decoration: none;
}

.HomePage_cv__2qLEy:hover {
  cursor: pointer;
  background-color: white;
  color: black;
  transition: 0.75s;
}

.HomePage_footericon__2Clz4 {
  border: 1px solid white;
  padding: 25px 2.5px 0 2.5px;
  border-radius: 30px;
  font-size: 1.6vw;
  margin: 22vh 0 -22vh;
  animation: HomePage_MoveUpDown__rAOkS 1.5s linear infinite;
}

@keyframes HomePage_MoveUpDown__rAOkS {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px);
  }
  100% {
    transform: translateY(0);
  }
}

@media only screen and (max-width: 1024px) {
  .HomePage_HomePage__3wCJe {
    background-attachment: scroll;
  }
  .HomePage_hw__m2p1c {
    font-size: 4vw;
  }
  .HomePage_titles__19WuI {
    font-size: 8vw;
  }
  .HomePage_SocialAccounts__lN5Bw {
    width: 30vw;
  }
  .HomePage_SocialAccountLink__2-dUF {
    font-size: 3vw;
  }
  .HomePage_cv__2qLEy {
    margin-top: 15px;
    font-size: 2.5vw;
    padding: 20px 40px;
    border-radius: 50px;
  }
  .HomePage_footericon__2Clz4 {
    font-size: 3.2vw;
    margin: 32vh 0 -32vh;
  }
}

@media only screen and (max-width: 600px) {
  .HomePage_HomePage__3wCJe {
    background-attachment: scroll;
  }
  .HomePage_hw__m2p1c {
    font-size: 5vw;
  }
  .HomePage_titles__19WuI {
    font-size: 8vw;
  }
  .HomePage_SocialAccounts__lN5Bw {
    width: 45vw;
  }
  .HomePage_SocialAccountLink__2-dUF {
    font-size: 3vw;
  }
  .HomePage_cv__2qLEy {
    margin-top: 5px;
    font-size: 3.25vw;
    padding: 15px 30px;
  }
  .HomePage_footericon__2Clz4 {
    font-size: 4.8vw;
    margin: 30vh 0 -30vh;
  }
}

.About_AboutPage__3D2Za {
  height: 150%;
  width: 100%;
  text-align: center;
  margin: 0;
  padding: 0;
}

.About_AboutPageColumn1__2Lc7a {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 50vw;
  margin: auto;
}

.About_AboutPageColumn1head__xDVV_ {
  margin: 50px 0 0 0;
  padding: 10px;
  font-size: 40px;
}

.About_divider1__20bRJ:before {
  content: "";
  border-top: 1px solid black;
  flex: 1 1;
  position: relative;
  top: -7.5px;
}

.About_divider1__20bRJ {
  font-size: 50px;
  width: 100px;
  display: flex;
  align-items: center;
  text-align: center;
  margin: 0;
}

.About_divider1__20bRJ:after {
  content: "";
  border-top: 1px solid black;
  flex: 1 1;
  position: relative;
  top: -7.5px;
}

.About_AboutMeColumn1Lines__360j0 {
  color: #6c757d;
  margin: 0;
}

.About_AboutPageColumn2__3La4o {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 65vw;
  margin: auto;
}

@font-face {
  font-family: SignatureFont;
  src: url("/static/media/Holland Gateway.c2fc2beb.otf");
  font-display: swap;
}

.About_sign__2-Rgs {
  margin: 55px 0 15px 0;
  font-family: SignatureFont;
  font-size: 70px;
  font-weight: bolder;
  letter-spacing: 2px;
}

.About_AboutPageColumnTitle2__3DlPN {
  margin: 30px 0 0 0;
  padding: 10px;
  font-size: 40px;
}

.About_AboutPageColumnTags2__36eH1 {
  margin: 10px 0 0 0;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 270px;
  color: #6c757d;
  font-weight: bold;
}

.About_dot__2xP2j {
  height: 5px;
  width: 5px;
  background-color: #6c757d;
  border-radius: 50%;
  display: inline-block;
}

.About_AboutMeColumn2Lines__34ZU2 {
  color: #6c757d;
  margin: 10px 0 0 0;
  width: 68vw;
}

.About_AboutPageColumn3__pcIgS {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 90vw;
  margin: 20px auto 60px;
  /* background-color: gold; */
}

.About_leftcolumn__18XXc {
  flex: 1 1;
  margin: 10px;
}

.About_rightcolumn__3XqUY {
  flex: 1 1;
  margin: 10px;
}

@media only screen and (max-width: 1024px) {
  .About_AboutPageColumn1__2Lc7a {
    width: 70vw;
  }
  .About_AboutMeColumn2Lines__34ZU2 {
    width: 85vw;
  }
}

@media only screen and (max-width: 600px) {
  .About_AboutPageColumn1__2Lc7a {
    width: 60vw;
  }
  .About_AboutMeColumn2Lines__34ZU2 {
    width: 80vw;
  }
}

.Skills_columnbox__1vVvs {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 20px 0 10px 0;
}

.Skills_columnname__1DVyf {
  font-weight: bold;
  letter-spacing: 0.5px;
}

.Skills_columnpercent__1_gRc {
  letter-spacing: 0.5px;
  color: #6c757d;
}

.Skills_percentbarbox__34wv8 {
  display: flex;
  flex: 1 1;
  background-color: #e9ecef;
  border-radius: 10px;
}

.Skills_percentbar__tF8B5 {
  display: flex;
  border: 2px solid black;
  border-radius: 10px;
}

.Blogs_BlogsPage__3lgag {
  background: #f8f9fa;
  height: 100%;
  width: 100%;
  text-align: center;
  margin: 0;
  padding: 0;
  /* background-color: gold; */
}

.Blogs_BlogsPageRow1__pz0bn {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 50vw;
  margin: auto;
  /* background-color: hotpink; */
}

.Blogs_BlogsPageRow1Head__vecT3 {
  margin: 50px 0 0 0;
  padding: 10px;
  font-size: 40px;
}

.Blogs_divider1__UHj-q:before {
  content: "";
  border-top: 1px solid black;
  flex: 1 1;
  position: relative;
  top: -7.5px;
}

.Blogs_divider1__UHj-q {
  font-size: 50px;
  width: 100px;
  display: flex;
  align-items: center;
  text-align: center;
  margin: 0;
}

.Blogs_divider1__UHj-q:after {
  content: "";
  border-top: 1px solid black;
  flex: 1 1;
  position: relative;
  top: -7.5px;
}

.Blogs_BlogsPageRow1Line__2c-r2 {
  color: #6c757d;
  margin: 0;
}

.Blogs_Box__2Lvxy {
  height: 250px;
  width: 90%;
  margin: 5%;
  display: flex;
  justify-content: space-between;
  /* background-color: blue; */
}

@media only screen and (max-width: 1024px) {
}

@media only screen and (max-width: 600px) {
  .Blogs_Box__2Lvxy {
    flex-direction: column;
    height: 500px;
    align-items: center;
  }
}

.Cards_CardsPageContainer__3XLqJ {
  border-radius: 10%;
  height: 100%;
  width: 20%;
  margin: 0;
  padding: 0;
  overflow: hidden;
  cursor: pointer;
  text-decoration: none;
  color: black;
}

.Cards_CardImg__36EeY {
  width: 100%;
  height: 100%;
}

.Cards_AboutCard__2nq8B {
  position: relative;
  margin: 0;
  padding: 0;
  transition: 0.7s ease-in-out;
  height: 100%;
  border-radius: 10%;
  background-color: #6c757d;
  opacity: 0;
  font-size: 1.5vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.Cards_CardImg__36EeY:hover + .Cards_AboutCard__2nq8B{
  transform: translate(0%, -101%);
  -ms-transform: translate(-50%, -50%);
  opacity: 0.8;
}

 .Cards_AboutCard__2nq8B:hover { 
  transform: translate(0%, -101%);
  -ms-transform: translate(-50%, -50%);
  opacity: 0.8;
} 

.Cards_AboutCardHead__kJFFy {
  margin: 0 0 5px 0;
  padding: 10px;
  font-size: 2.25vw;
  /* background-color: greenyellow; */
}

.Cards_AboutCardLines__2N7UT {
  margin: 5px 0 0 0;
  padding: 10px;
  font-size: 1.25vw;
  /* background-color: hotpink; */
}

@media only screen and (max-width: 1024px) {
  .Cards_CardsPageContainer__3XLqJ {
    height: 80%;
  }
}

@media only screen and (max-width: 600px) {
  .Cards_CardsPageContainer__3XLqJ {
    margin: 10px;
    width: 30%;
  }
}

.Contact_ContactPage__3NjpA {
  height: 120%;
  width: 100%;
  text-align: center;
  margin: 0;
  padding: 0;
}

.Contact_ContactPageColumn1__jHFRi {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 50vw;
  margin: auto;
}

.Contact_ContactPageColumn1head__2z_EZ {
  margin: 50px 0 0 0;
  padding: 10px;
  font-size: 40px;
}

.Contact_divider1__1i-1U:before {
  content: "";
  border-top: 1px solid black;
  flex: 1 1;
  position: relative;
  top: -7.5px;
}

.Contact_divider1__1i-1U {
  font-size: 50px;
  width: 100px;
  display: flex;
  align-items: center;
  text-align: center;
  margin: 0;
}

.Contact_divider1__1i-1U:after {
  content: "";
  border-top: 1px solid black;
  flex: 1 1;
  position: relative;
  top: -7.5px;
}

.Contact_ContactLines__2cCkV {
  color: #6c757d;
  margin: 0;
  padding: 0;
  width: 550px;
}

.Contact_ContactPageColumn2__3Rrgo {
  display: flex;
  justify-content: center;
  width: 300px;
  margin: 20px 0 0 90px;
  float: left;
}

.Contact_Option1__3ACzX,
.Contact_Option2__20XIG,
.Contact_Option3__1J5Df {
  margin-top: 20px;
}

.Contact_phoneicon__1K7c8,
.Contact_emailicon__28te0,
.Contact_mapicon__2K3E6 {
  font-size: 40px;
}

.Contact_Option1__3ACzX h4,
.Contact_Option2__20XIG h4,
.Contact_Option3__1J5Df h4 {
  margin: 10px 0 0 0;
}

.Contact_Option1__3ACzX p,
.Contact_Option2__20XIG p,
.Contact_Option3__1J5Df p {
  margin: 10px 0 40px 0;
  color: #6c757d;
}

.Contact_ContactPageColumn3__3X6zD {
  display: flex;
  width: 60%;
  margin: 20px 0 0 0;
}

.Contact_ContactForm__qyMeo {
  display: flex;
  flex-direction: column;
  width: 700px;
  margin: auto;
}

.Contact_ContactFormLabel__QGMA3 {
  font-weight: bold;
  align-self: start;
  margin: 15px 0 10px;
}

.Contact_ContactForm__qyMeo input {
  flex: 1 1;
  padding: 10px;
  background: transparent;
  font-size: 16px;
  border: 1px solid #ced4da;
}

.Contact_ContactForm__qyMeo input:focus {
  outline: 0;
  border: 1px solid black;
}

.Contact_ContactForm__qyMeo textarea {
  font-size: 16px;
  height: 70px;
  padding: 10px;
  flex: 1 1;
  background: transparent;
  border: 1px solid #ced4da;
}

.Contact_ContactForm__qyMeo textarea:focus {
  outline: 0;
  border: 1px solid black;
}

.Contact_lastColumn__1jsL6 {
  align-self: flex-end;
  margin: 20px 0;
}

.Contact_successMessage__6Ldhq {
  display: inline;
  background-color: rgb(101, 212, 101);
  color: white;
  padding: 15px 25px;
  margin: 0 20px 0 0;
  border-radius: 10px;
  opacity: 0.8;
  font-weight: bold;
  animation: Contact_fade__151vn 10s ease;
}

@keyframes Contact_fade__151vn {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.Contact_ContactFormSendButton__gPHdc {
  padding: 15px 25px;
  background-color: black;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
}

@media only screen and (max-width: 1024px) {
  .Contact_ContactLines__2cCkV {
    margin-bottom: 10px;
  }
  .Contact_ContactPageColumn2__3Rrgo {
    margin: auto;
    width: 200px;
    margin: 0 0 0 15px;
  }
  .Contact_ContactPageColumn3__3X6zD {
    margin: auto;
    width: 60vw;
  }
}

@media only screen and (max-width: 600px) {
  .Contact_ContactLines__2cCkV {
    width: 300px;
    margin-bottom: 20px;
  }
  .Contact_ContactPageColumn2__3Rrgo {
    float: none;
    margin: auto;
  }
  .Contact_ContactPageColumn3__3X6zD {
    float: none;
    margin: auto;
    width: 80vw;
  }
  .Contact_lastColumn__1jsL6 {
    justify-content: space-around;
    font-size: 13px;
  }
  .Contact_ContactFormSendButton__gPHdc {
    font-size: 13px;
  }
}

.Loader_loaderpage__3GPcw {
  position: absolute;
  width: 100%;
  height: 120%;
  background-color: grey;
  opacity: 0.7;
  z-index: 50;
  display: flex;
  justify-content: center;
  align-items: center;
}

.MenuBar_TransMenuBar__3WvBu {
  min-height: 12vh;
  width: 100%;
  position: fixed;
  display: flex;
  justify-content: space-between;
  z-index: 100;
  transition: 1s;
}

.MenuBar_WhiteMenuBar__ulYhm {
  min-height: 12vh;
  width: 100%;
  background-color: white;
  position: fixed;
  display: flex;
  justify-content: space-between;
  z-index: 100;
  transition: 1s;
}

.MenuBar_logo__hZRJc {
  width: 8vw;
  height: 100%;
  margin: auto 0 auto 75px;
  display: flex;
  align-self: center;
  justify-content: center;
}

.MenuBar_MenuItems__2NUnS {
  display: flex;
  justify-content: space-between;
  width: 23vw;
  margin: auto 75px auto 0;
  padding: 10px;
  list-style: none;
  font-weight: 700;
}

.MenuBar_MenuItem__2udZx {
  cursor: pointer;
  text-decoration: none;
  padding: 1vh 1vw;
  display: flex;
}

.MenuBar_WhiteMenuLink__2QCj_ {
  font-size: 1.3vw;
  color: white;
  text-decoration: none;
}

.MenuBar_BlackMenuLink__3xLun {
  font-size: 1.3vw;
  color: black;
  text-decoration: none;
}

@media only screen and (max-width: 1024px) {
  .MenuBar_logo__hZRJc {
    width: 14vw;
  }
  .MenuBar_MenuItems__2NUnS {
    margin-right: 200px;
  }
  .MenuBar_WhiteMenuLink__2QCj_ {
    font-size: 2.5vw;
    margin: auto 5px;
  }
  .MenuBar_BlackMenuLink__3xLun {
    font-size: 2.5vw;
    margin: auto 5px;
  }
}

@media only screen and (max-width: 600px) {
  .MenuBar_logo__hZRJc {
    width: 15vw;
    margin-left: 15px;
    /* background-color: burlywood; */
  }
  .MenuBar_MenuItems__2NUnS {
    margin-right: 140px;
  }
  .MenuBar_WhiteMenuLink__2QCj_ {
    font-size: 3vw;
  }
  .MenuBar_BlackMenuLink__3xLun {
    font-size: 3vw;
  }
}

