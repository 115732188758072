.columnbox {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 20px 0 10px 0;
}

.columnname {
  font-weight: bold;
  letter-spacing: 0.5px;
}

.columnpercent {
  letter-spacing: 0.5px;
  color: #6c757d;
}

.percentbarbox {
  display: flex;
  flex: 1;
  background-color: #e9ecef;
  border-radius: 10px;
}

.percentbar {
  display: flex;
  border: 2px solid black;
  border-radius: 10px;
}
