.BlogsPage {
  background: #f8f9fa;
  height: 100%;
  width: 100%;
  text-align: center;
  margin: 0;
  padding: 0;
  /* background-color: gold; */
}

.BlogsPageRow1 {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 50vw;
  margin: auto;
  /* background-color: hotpink; */
}

.BlogsPageRow1Head {
  margin: 50px 0 0 0;
  padding: 10px;
  font-size: 40px;
}

.divider1:before {
  content: "";
  border-top: 1px solid black;
  flex: 1;
  position: relative;
  top: -7.5px;
}

.divider1 {
  font-size: 50px;
  width: 100px;
  display: flex;
  align-items: center;
  text-align: center;
  margin: 0;
}

.divider1:after {
  content: "";
  border-top: 1px solid black;
  flex: 1;
  position: relative;
  top: -7.5px;
}

.BlogsPageRow1Line {
  color: #6c757d;
  margin: 0;
}

.Box {
  height: 250px;
  width: 90%;
  margin: 5%;
  display: flex;
  justify-content: space-between;
  /* background-color: blue; */
}

@media only screen and (max-width: 1024px) {
}

@media only screen and (max-width: 600px) {
  .Box {
    flex-direction: column;
    height: 500px;
    align-items: center;
  }
}
