.AboutPage {
  height: 150%;
  width: 100%;
  text-align: center;
  margin: 0;
  padding: 0;
}

.AboutPageColumn1 {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 50vw;
  margin: auto;
}

.AboutPageColumn1head {
  margin: 50px 0 0 0;
  padding: 10px;
  font-size: 40px;
}

.divider1:before {
  content: "";
  border-top: 1px solid black;
  flex: 1;
  position: relative;
  top: -7.5px;
}

.divider1 {
  font-size: 50px;
  width: 100px;
  display: flex;
  align-items: center;
  text-align: center;
  margin: 0;
}

.divider1:after {
  content: "";
  border-top: 1px solid black;
  flex: 1;
  position: relative;
  top: -7.5px;
}

.AboutMeColumn1Lines {
  color: #6c757d;
  margin: 0;
}

.AboutPageColumn2 {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 65vw;
  margin: auto;
}

@font-face {
  font-family: SignatureFont;
  src: url(./2.0\ Font/Holland\ Gateway.otf);
  font-display: swap;
}

.sign {
  margin: 55px 0 15px 0;
  font-family: SignatureFont;
  font-size: 70px;
  font-weight: bolder;
  letter-spacing: 2px;
}

.AboutPageColumnTitle2 {
  margin: 30px 0 0 0;
  padding: 10px;
  font-size: 40px;
}

.AboutPageColumnTags2 {
  margin: 10px 0 0 0;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 270px;
  color: #6c757d;
  font-weight: bold;
}

.dot {
  height: 5px;
  width: 5px;
  background-color: #6c757d;
  border-radius: 50%;
  display: inline-block;
}

.AboutMeColumn2Lines {
  color: #6c757d;
  margin: 10px 0 0 0;
  width: 68vw;
}

.AboutPageColumn3 {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 90vw;
  margin: 20px auto 60px;
  /* background-color: gold; */
}

.leftcolumn {
  flex: 1;
  margin: 10px;
}

.rightcolumn {
  flex: 1;
  margin: 10px;
}

@media only screen and (max-width: 1024px) {
  .AboutPageColumn1 {
    width: 70vw;
  }
  .AboutMeColumn2Lines {
    width: 85vw;
  }
}

@media only screen and (max-width: 600px) {
  .AboutPageColumn1 {
    width: 60vw;
  }
  .AboutMeColumn2Lines {
    width: 80vw;
  }
}
