.ContactPage {
  height: 120%;
  width: 100%;
  text-align: center;
  margin: 0;
  padding: 0;
}

.ContactPageColumn1 {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 50vw;
  margin: auto;
}

.ContactPageColumn1head {
  margin: 50px 0 0 0;
  padding: 10px;
  font-size: 40px;
}

.divider1:before {
  content: "";
  border-top: 1px solid black;
  flex: 1;
  position: relative;
  top: -7.5px;
}

.divider1 {
  font-size: 50px;
  width: 100px;
  display: flex;
  align-items: center;
  text-align: center;
  margin: 0;
}

.divider1:after {
  content: "";
  border-top: 1px solid black;
  flex: 1;
  position: relative;
  top: -7.5px;
}

.ContactLines {
  color: #6c757d;
  margin: 0;
  padding: 0;
  width: 550px;
}

.ContactPageColumn2 {
  display: flex;
  justify-content: center;
  width: 300px;
  margin: 20px 0 0 90px;
  float: left;
}

.Option1,
.Option2,
.Option3 {
  margin-top: 20px;
}

.phoneicon,
.emailicon,
.mapicon {
  font-size: 40px;
}

.Option1 h4,
.Option2 h4,
.Option3 h4 {
  margin: 10px 0 0 0;
}

.Option1 p,
.Option2 p,
.Option3 p {
  margin: 10px 0 40px 0;
  color: #6c757d;
}

.ContactPageColumn3 {
  display: flex;
  width: 60%;
  margin: 20px 0 0 0;
}

.ContactForm {
  display: flex;
  flex-direction: column;
  width: 700px;
  margin: auto;
}

.ContactFormLabel {
  font-weight: bold;
  align-self: start;
  margin: 15px 0 10px;
}

.ContactForm input {
  flex: 1;
  padding: 10px;
  background: transparent;
  font-size: 16px;
  border: 1px solid #ced4da;
}

.ContactForm input:focus {
  outline: 0;
  border: 1px solid black;
}

.ContactForm textarea {
  font-size: 16px;
  height: 70px;
  padding: 10px;
  flex: 1;
  background: transparent;
  border: 1px solid #ced4da;
}

.ContactForm textarea:focus {
  outline: 0;
  border: 1px solid black;
}

.lastColumn {
  align-self: flex-end;
  margin: 20px 0;
}

.successMessage {
  display: inline;
  background-color: rgb(101, 212, 101);
  color: white;
  padding: 15px 25px;
  margin: 0 20px 0 0;
  border-radius: 10px;
  opacity: 0.8;
  font-weight: bold;
  animation: fade 10s ease;
}

@-webkit-keyframes fade {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes fade {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.ContactFormSendButton {
  padding: 15px 25px;
  background-color: black;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
}

@media only screen and (max-width: 1024px) {
  .ContactLines {
    margin-bottom: 10px;
  }
  .ContactPageColumn2 {
    margin: auto;
    width: 200px;
    margin: 0 0 0 15px;
  }
  .ContactPageColumn3 {
    margin: auto;
    width: 60vw;
  }
}

@media only screen and (max-width: 600px) {
  .ContactLines {
    width: 300px;
    margin-bottom: 20px;
  }
  .ContactPageColumn2 {
    float: none;
    margin: auto;
  }
  .ContactPageColumn3 {
    float: none;
    margin: auto;
    width: 80vw;
  }
  .lastColumn {
    justify-content: space-around;
    font-size: 13px;
  }
  .ContactFormSendButton {
    font-size: 13px;
  }
}
