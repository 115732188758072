.TransMenuBar {
  min-height: 12vh;
  width: 100%;
  position: fixed;
  display: flex;
  justify-content: space-between;
  z-index: 100;
  transition: 1s;
}

.WhiteMenuBar {
  min-height: 12vh;
  width: 100%;
  background-color: white;
  position: fixed;
  display: flex;
  justify-content: space-between;
  z-index: 100;
  transition: 1s;
}

.logo {
  width: 8vw;
  height: 100%;
  margin: auto 0 auto 75px;
  display: flex;
  align-self: center;
  justify-content: center;
}

.MenuItems {
  display: flex;
  justify-content: space-between;
  width: 23vw;
  margin: auto 75px auto 0;
  padding: 10px;
  list-style: none;
  font-weight: 700;
}

.MenuItem {
  cursor: pointer;
  text-decoration: none;
  padding: 1vh 1vw;
  display: flex;
}

.WhiteMenuLink {
  font-size: 1.3vw;
  color: white;
  text-decoration: none;
}

.BlackMenuLink {
  font-size: 1.3vw;
  color: black;
  text-decoration: none;
}

@media only screen and (max-width: 1024px) {
  .logo {
    width: 14vw;
  }
  .MenuItems {
    margin-right: 200px;
  }
  .WhiteMenuLink {
    font-size: 2.5vw;
    margin: auto 5px;
  }
  .BlackMenuLink {
    font-size: 2.5vw;
    margin: auto 5px;
  }
}

@media only screen and (max-width: 600px) {
  .logo {
    width: 15vw;
    margin-left: 15px;
    /* background-color: burlywood; */
  }
  .MenuItems {
    margin-right: 140px;
  }
  .WhiteMenuLink {
    font-size: 3vw;
  }
  .BlackMenuLink {
    font-size: 3vw;
  }
}
